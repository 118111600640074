import React from 'react';
import injectSheet from 'react-jss';

import Translate from '../../../components/Translate';
import Layout from '../../../components/layout';
import IndexSmartScaleTabs from '../../../components/indexSmartScaleTabs';
import installBatteryImage from '../../../data/images/start/install-batteries-index.png';
import downloadImage from '../../../data/images/start/download-gcm.png';
import deviceImage from '../../../data/images/start/add-device.png';
import wrench from '../../../data/images/SVG-files/icon-wrench.svg';
import bluetooth from '../../../data/images/SVG-files/icon-bluetooth.svg';
import wifi from '../../../data/images/SVG-files/icon-wifi-dark.svg';
import ant from '../../../data/images/SVG-files/icon-ant.svg';
import { isChinaUser } from '../../../config/isChina';
import themeColors from '../../../styles/theme';

const styles = {
    wrapper: {
        backgroundColor: '#fff'
    },
    content: {
        width: '80%',
        paddingLeft: '10%',
        paddingTop: '80px',
        '& > div:not(:last-child)': {
            borderBottom: '1px solid rgba(199,199,199,0.3)',
            paddingBottom: '20px'
        }
    },
    section: {
        display: 'inline-flex',
        marginBottom: '40px',
        '& h4': {
            marginBlockEnd: '0',
            marginBlockStart: '0',
            fontSize: '23px',
            lineHeight: '1.15',
            fontWeight: '300'
        },
        '& a': {
            color: themeColors.primaryTextLinks,
            outline: 'none'
        }
    },
    image: {
        width: '60%'
    },
    number: {
        minWidth: '40px',
        height: '40px',
        marginRight: '50px',
        '& span': {
            width: '40px',
            border: '1px solid',
            height: '40px',
            borderRadius: '50%',
            fontSize: '14px',
            textAlign: 'center',
            lineHeight: '40px',
            display: 'block'
        }
    },
    text: {
        marginRight: '50px',
        width: '80%'
    },
    icon: {
        width: '15px',
        height: '15px'
    },
    '@media only screen and (max-width: 767px)': {
        content: {
            width: '90%',
            paddingLeft: '5%'
        },
        section: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > span': {
                textAlign: 'center'
            }
        },
        text: {
            width: '100%',
            margin: '0 0 20px 0',
            '& h4': {
                textAlign: 'center'
            }
        },
        number: {
            margin: '0 0 20px 0',
            textAlign: 'center'
        }
    }
};

class Mobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isChinaUser: false
        };
    }

    componentDidMount() {
        this.setState({ isChinaUser: isChinaUser() });
    }

    render() {
        const { classes } = this.props;
        const appleLink = this.state.isChinaUser
            ? 'https://itunes.apple.com/cn/app/garmin-connect-mobile/id583446403?mt=8'
            : 'https://itunes.apple.com/us/app/garmin-connect-mobile/id583446403';
        const androidLink = this.state.isChinaUser
            ? 'https://download.garmin.cn/cn/download/CH_SW_UPGRADE/Android/garmin-connect-mobile.apk'
            : 'https://play.google.com/store/apps/details?id=com.garmin.android.apps.connectmobile';

        return (
            <Layout backgroundColor="#fafafa">
                <div>
                    <IndexSmartScaleTabs selectedTab="mobile" />
                    <div className={classes.wrapper}>
                        <div className={classes.content}>
                            <div className={classes.section}>
                                <div className={classes.number}>
                                    <span>1</span>
                                </div>
                                <div className={classes.text}>
                                    <h4>{<Translate ns="prelogin_pages" content="install_batteries" />}</h4>
                                    <p>
                                        {
                                            <Translate
                                                ns="prelogin_pages"
                                                content="install_batteries_instructions"
                                                params={{
                                                    0: (
                                                        <Translate ns="prelogin_pages">
                                                            {(t) => (
                                                                <img
                                                                    src={wrench}
                                                                    className={classes.icon}
                                                                    alt={t('wrench')}
                                                                />
                                                            )}
                                                        </Translate>
                                                    ),
                                                    1: (
                                                        <Translate ns="prelogin_pages">
                                                            {(t) => (
                                                                <img
                                                                    src={bluetooth}
                                                                    className={classes.icon}
                                                                    alt={t('bluetooth')}
                                                                />
                                                            )}
                                                        </Translate>
                                                    ),
                                                    2: (
                                                        <Translate ns="prelogin_pages">
                                                            {(t) => (
                                                                <img
                                                                    src={ant}
                                                                    className={classes.icon}
                                                                    alt={t('ant_status')}
                                                                />
                                                            )}
                                                        </Translate>
                                                    ),
                                                    3: (
                                                        <Translate ns="activity-sync">
                                                            {(t) => (
                                                                <img
                                                                    src={wifi}
                                                                    className={classes.icon}
                                                                    alt={t('how.to.wifi.title')}
                                                                />
                                                            )}
                                                        </Translate>
                                                    )
                                                }}
                                            />
                                        }
                                    </p>
                                </div>
                                <span>
                                    <img className={classes.image} src={installBatteryImage} alt="" />
                                </span>
                            </div>

                            <div className={classes.section}>
                                <div className={classes.number}>
                                    <span>2</span>
                                </div>
                                <div className={classes.text}>
                                    <h4>{<Translate ns="prelogin_pages" content="download_install" />}</h4>
                                    <p>{<Translate ns="prelogin_pages" content="download_install_content" />}</p>
                                    <p>
                                        <a href={appleLink}>
                                            {<Translate ns="prelogin_pages" content="download_ios" />}
                                        </a>
                                    </p>
                                    <p>
                                        <a href={androidLink}>
                                            {<Translate ns="prelogin_pages" content="download_android" />}
                                        </a>
                                    </p>
                                </div>
                                <span>
                                    <img className={classes.image} src={downloadImage} alt="" />
                                </span>
                            </div>

                            <div className={classes.section}>
                                <div className={classes.number}>
                                    <span>3</span>
                                </div>
                                <div className={classes.text}>
                                    <h4>{<Translate ns="prelogin_pages" content="add_device" />}</h4>
                                    <p>{<Translate ns="prelogin_pages" content="add_device_content" />}</p>
                                </div>
                                <span>
                                    <img className={classes.image} src={deviceImage} alt="" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default injectSheet(styles)(Mobile);
